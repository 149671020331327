import { Injectable } from '@angular/core';
import { ContentService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IContentStats } from 'lingo2-models';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { loadStats, loadStatsSuccess, requestFail } from './stats.actions';

@Injectable()
export class StatsEffects {
  constructor(private actions$: Actions, private contentService: ContentService) {}

  loadStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStats),
      switchMap(() =>
        this.contentService.getStats().pipe(
          map((stats: IContentStats) => loadStatsSuccess({ stats })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );
}
