import { Action, createReducer, on } from '@ngrx/store';
import { Lesson, LessonSlide } from 'lingo2-models';
import { cloneDeep } from 'lodash';

import { ILessonComplete } from '../../content-form-page/_models';
import { ILessonVisitInfo } from './lesson.models';
import { clearLesson, copySection, copySectionSuccess, copySlide, copySlideSuccess, createLesson, createLessonBySection, createLessonBySectionSuccess, createLessonBySlide, createLessonBySlideSuccess, createLessonSuccess, createSection, createSectionSuccess, createSlide, createSlideSuccess, loadLesson, loadLessonSuccess, newLesson, newSlide, publishLesson, publishLessonFail, publishLessonSuccess, removeLesson, removeLessonSuccess, removeSection, removeSectionSuccess, removeSlide, removeSlideSuccess, requestFail, saveLesson, setIsEditing, setIsErrorsVisible, setLesson, setLessonVisitInfo, updateLesson, updateLessonFail, updateLessonSuccess, updateSection, updateSectionFail, updateSections, updateSectionsSuccess, updateSectionSuccess, updateSlide, updateSlideFail, updateSlides, updateSlidesSuccess, updateSlideSuccess, updateValidateSections, validateLesson } from './lesson.actions';

export interface LessonState {
  entity: Lesson;
  validity: ILessonComplete;
  visit_info: ILessonVisitInfo;
  isEditing: boolean;
  isErrorsVisible: boolean;
  loading: boolean;
  loaded: boolean;
  creating: boolean;
  created: boolean;
  publishing: boolean;
  published: boolean;
  saving: boolean;
  saved: boolean;
  error: string;
}

const initialState: LessonState = {
  entity: null,
  validity: null,
  visit_info: null,
  isEditing: false,
  isErrorsVisible: false,
  loading: false,
  loaded: false,
  creating: false,
  created: false,
  publishing: false,
  published: null,
  saving: null,
  saved: null,
  error: null,
};

const contentReducer = createReducer(
  initialState,
  on(requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  // Lesson

  on(loadLesson, (state) => ({ ...state, loading: true, error: null })),

  on(loadLessonSuccess, (state, { lesson }) => ({ ...state, entity: lesson, loading: false })),

  on(createLesson, (state) => ({ ...state, loading: true, error: null, creating: true })),

  on(createLessonSuccess, (state, { lesson }) => ({
    ...state,
    entity: lesson,
    loading: false,
    creating: false,
    created: true,
  })),

  on(createLessonBySection, (state) => ({ ...state, loading: true, creating: true, error: null })),

  on(createLessonBySectionSuccess, (state, { lesson }) => ({
    ...state,
    entity: lesson,
    loading: false,
    creating: false,
    created: true,
  })),

  on(createLessonBySlide, (state) => ({ ...state, loading: true, creating: true, error: null })),

  on(createLessonBySlideSuccess, (state, { lesson }) => ({
    ...state,
    entity: lesson,
    loading: false,
    creating: false,
    created: true,
  })),

  on(updateLesson, (state) => ({ ...state, saving: true, saved: false, error: null })),

  on(updateLessonFail, (state, { error }) => ({ ...state, saving: false, saved: false, error })),

  on(updateLessonSuccess, (state, { lesson }) => ({
    ...state,
    entity: { ...state.entity, ...lesson } as Lesson,
    saving: false,
    saved: true,
    error: null,
  })),

  on(removeLesson, (state) => ({ ...state, loading: true, error: null })),

  on(removeLessonSuccess, (state) => ({ ...state, entity: null, loading: false })),

  on(setLesson, (state, { lesson }) => ({ ...state, entity: lesson, loading: false, error: null })),

  on(newLesson, (state) => {
    const lesson = new Lesson({ id: 'new' });
    const slide = new LessonSlide({ id: 'new', lesson_id: 'new' });
    slide.sections = [];
    lesson.slides.push(slide);
    return { ...state, entity: lesson };
  }),

  on(clearLesson, (state) => ({ ...initialState })),

  on(publishLesson, (state) => ({ ...state, publishing: true, published: false, error: null })),

  on(publishLessonFail, (state, { error }) => ({ ...state, publishing: false, published: false, error })),

  on(publishLessonSuccess, (state, { lesson }) => ({
    ...state,
    entity: {
      ...state.entity,
      published_at: lesson.published_at,
      cover: lesson.cover,
      subject: lesson.subject,
    } as Lesson,
    publishing: false,
    published: true,
  })),

  on(saveLesson, (state) => ({
    ...state,
    error: null,
    published: true,
    publishing: false,
  })),

  on(setIsEditing, (state, { isEditing }) => ({ ...state, isEditing })),

  on(setIsErrorsVisible, (state, { isErrorsVisible }) => ({ ...state, isErrorsVisible })),

  // Slide

  on(createSlide, (state) => ({ ...state, loading: true, error: null })),

  on(createSlideSuccess, (state, { slide }) => {
    const lesson = cloneDeep(state.entity);
    if (!lesson.slides) {
      lesson.slides = [];
    }
    // TODO: проверить создание слайда при клонировании
    lesson.slides.push({ ...slide, sections: slide.sections || [] });
    return { ...state, entity: lesson, loading: false };
  }),

  on(updateSlide, (state) => ({ ...state, saving: true, saved: false, error: null })),

  on(updateSlideFail, (state, { error }) => ({ ...state, saving: false, saved: false, error })),

  on(updateSlideSuccess, (state, { id, slide }) => {
    const lesson = cloneDeep(state.entity);
    let currentSlide = lesson.slides.find((x) => x.id === id);
    currentSlide = { ...slide };
    return { ...state, entity: lesson, saving: false, saved: true, error: null };
  }),

  on(removeSlide, (state) => ({ ...state, loading: true, error: null })),

  on(removeSlideSuccess, (state, { id }) => {
    const lesson = cloneDeep(state.entity);
    const slideIndex = lesson.slides.findIndex((x) => x.id === id);
    lesson.slides.splice(slideIndex, 1);
    return { ...state, entity: lesson, loading: false };
  }),

  on(newSlide, (state) => {
    const lesson = cloneDeep(state.entity);
    if (!lesson.slides) {
      lesson.slides = [];
    }
    const existedNewSlide = lesson.slides.find((x) => x.id === 'new');
    if (!existedNewSlide) {
      const slide = new LessonSlide({ id: 'new', lesson_id: lesson.id });
      slide.sections = [];
      lesson.slides.push(slide);
    }

    return { ...state, entity: lesson };
  }),

  on(copySlide, (state) => ({ ...state, loading: true, error: null })),

  on(copySlideSuccess, (state, { slide }) => {
    const lesson = cloneDeep(state.entity);
    if (!lesson.slides) {
      lesson.slides = [];
    }
    lesson.slides.push(slide);
    return { ...state, entity: lesson, loading: false };
  }),

  on(updateSlides, (state) => ({ ...state, loading: true, error: null })),

  on(updateSlidesSuccess, (state, { slides }) => {
    const lesson = cloneDeep(state.entity);
    if (lesson && lesson.slides) {
      slides.forEach((s) => {
        const index = lesson.slides.findIndex((x) => x.id === s.id);
        if (index > -1) {
          const slide = lesson.slides[index];
          lesson.slides[index] = { ...slide, ...s };
        }
      });
    }
    return { ...state, entity: lesson, loading: false };
  }),

  on(validateLesson, (state, { validity }) => ({ ...state, validity })),

  // Section

  on(createSection, (state) => ({ ...state, loading: true, error: null })),

  on(createSectionSuccess, (state, { slide_id, section }) => {
    const lesson = cloneDeep(state.entity);
    const slide = lesson.slides.find((x) => x.id === slide_id);
    if (!slide.sections) {
      slide.sections = [];
    }
    slide.sections.push(section);
    return { ...state, entity: lesson, loading: false };
  }),

  on(updateSection, (state) => ({ ...state, saving: true, saved: false, error: null })),

  on(updateSectionFail, (state, { error }) => ({ ...state, saving: false, saved: false, error })),

  on(updateSectionSuccess, (state, { id, section }) => {
    // logger.log('--- reducer section = ', section);
    const lesson = cloneDeep(state.entity);
    if (lesson) {
      let currentSection;
      lesson.slides.find((slide) => {
        currentSection = slide.sections.find((x) => x.id === id);
        return !!currentSection;
      });

      if (currentSection.id) {
        currentSection = Object.assign(currentSection, { ...section });
      }
    }

    // logger.log('--- reducer lesson = ', lesson);
    return { ...state, entity: lesson, saving: false, saved: true, error: null };
  }),

  on(removeSection, (state) => ({ ...state, loading: true, error: null })),

  on(removeSectionSuccess, (state, { id }) => {
    const lesson = cloneDeep(state.entity);
    lesson.slides.find((slide) => {
      const sectionIndex = slide.sections.findIndex((x) => x.id === id);
      if (sectionIndex > -1) {
        slide.sections.splice(sectionIndex, 1);
        return true;
      }
      return false;
    });
    return { ...state, entity: lesson, loading: false };
  }),

  on(copySection, (state) => ({ ...state, loading: true, error: null })),

  on(copySectionSuccess, (state, { section }) => {
    const lesson = cloneDeep(state.entity);
    lesson.slides.find((x) => x.id === section.slide_id).sections.push(section);
    return { ...state, entity: lesson, loading: false };
  }),

  on(updateSections, (state) => ({ ...state, loading: true, error: null })),

  on(updateValidateSections, (state, { slide_id, show_validate }) => {
    const lesson = cloneDeep(state.entity);
    const slide = lesson.slides.find((x) => x.id === slide_id);
    if (lesson && lesson.slides && slide.sections) {
      slide.sections = slide.sections.map((s) => ({ ...s, show_validate }));
    }
    return { ...state, entity: lesson, loading: true, error: null };
  }),

  on(updateSectionsSuccess, (state, { sections }) => {
    const lesson = cloneDeep(state.entity);
    if (lesson && lesson.slides) {
      sections.forEach((s) => {
        const slide = lesson.slides.find((x) => x.id === s.slide_id);
        const sectionIndex = slide.sections.findIndex((x) => x.id === s.id);
        if (sectionIndex > -1) {
          const section = slide.sections[sectionIndex];
          slide.sections[sectionIndex] = { ...section, ...s };
        }
      });
    }
    return { ...state, entity: lesson, loading: false };
  }),

  // Visit info
  on(setLessonVisitInfo, (state, { visit_info }) => ({
    ...state,
    visit_info: { ...state.visit_info, ...visit_info },
  })),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
