import { IContentBlock } from '@core/services/interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import { Lesson, IFindContentFilter, IContentStats } from 'lingo2-models';
import { loadStats, loadStatsSuccess, requestFail } from './stats.actions';

export type LibraryBlock = IContentBlock<Lesson, IFindContentFilter>;

export interface StatsState {
  entity: IContentStats;
  loading: boolean;
  error: string;
}

const initialState: StatsState = {
  entity: null,
  loading: false,
  error: null,
};

const contentReducer = createReducer(
  initialState,

  on(requestFail, (state, { error }) => ({ ...state, loading: false, error })),

  on(loadStats, (state) => ({ ...state, loading: true, error: null })),

  on(loadStatsSuccess, (state, { stats }) => ({ ...state, entity: stats, loading: false })),
);

export function reducer(state = initialState, action: Action) {
  return contentReducer(state, action);
}
