import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { getMergedRoute } from '@app/store/reducers/router.reducer';
import { ConstructorService, ContentService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ILessonSlide, ILessonSlideSection, Lesson, LessonSlide } from 'lingo2-models';
import { cloneDeep } from 'lodash';
import { forkJoin, of, zip } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  copySection,
  copySectionSuccess,
  copySlide,
  copySlideSuccess,
  createLesson,
  createLessonBySection,
  createLessonBySectionSuccess,
  createLessonBySlide,
  createLessonBySlideSuccess,
  createLessonSuccess,
  createSection,
  createSectionSuccess,
  createSlide,
  createSlideSuccess,
  loadLesson,
  loadLessonSuccess,
  loadSection,
  loadSectionSuccess,
  loadSlide,
  loadSlideSuccess,
  publishLesson,
  publishLessonSuccess,
  removeLesson,
  removeLessonSuccess,
  removeSection,
  removeSectionSuccess,
  removeSlide,
  removeSlideSuccess,
  requestFail,
  updateLesson,
  updateLessonSuccess,
  updateSection,
  updateSections,
  updateSectionsSuccess,
  updateSectionSuccess,
  updateSlide,
  updateSlides,
  updateSlidesSuccess,
  updateSlideSuccess,
} from './lesson.actions';
import { getLesson } from './lesson.selectors';

@Injectable()
export class LessonEffects {
  // Lesson CRUD

  public loadLesson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLesson),
      switchMap(({ id }) =>
        this.constructorService.getLesson(id).pipe(
          map((lesson: Lesson) => loadLessonSuccess({ lesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public createLesson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLesson),
      switchMap(({ lesson }) =>
        zip(of(lesson), this.constructorService.createLesson(), this.store.select(getMergedRoute)),
      ),
      switchMap(([lesson, fullLesson, route]) =>
        // logger.log('--createLesson route = ', route);
        this.constructorService.updateLesson(fullLesson.id, lesson).pipe(
          map((_lesson: Lesson) => createLessonSuccess({ lesson: _lesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  // При создании урока через "+" нужно создать 2 слайда
  public createLessonBySlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLessonBySlide),
      switchMap(() => this.constructorService.createLesson()),
      switchMap((lesson) =>
        zip(
          of(lesson),
          // Создаем первый слайд
          this.constructorService.createSlide(lesson.id),
        ),
      ),
      switchMap(([lesson, firstSlide]) =>
        // Создаем второй слайд
        this.constructorService.createSlide(lesson.id).pipe(
          map((slide: ILessonSlide) => ({ ...lesson, slides: [firstSlide, { ...slide }] })),
          tap((_lesson: Lesson) => {
            this.router.navigate(['/constructor/edit', _lesson.id, 'constructor', _lesson.slides[1].id]);
          }),
          map((_lesson: Lesson) => createLessonBySlideSuccess({ lesson: _lesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public createLessonBySection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLessonBySection),
      switchMap(({ section_type }) => zip(of(section_type), this.constructorService.createLesson())),
      switchMap(([section_type, lesson]) =>
        zip(of(section_type), of(lesson), this.constructorService.createSlide(lesson.id)),
      ),
      switchMap(([section_type, lesson, slide]) =>
        this.constructorService.createSection(slide.id, section_type).pipe(
          map(
            (section: ILessonSlideSection) =>
              new Lesson({
                ...lesson,
                slides: [{ ...slide, sections: [section] }],
              }),
          ),
          tap((fullLesson: Lesson) => {
            this.router.navigate(['/constructor/edit', fullLesson.id, 'constructor', fullLesson.slides[0].id]);
          }),
          map((fullLesson: Lesson) => createLessonBySectionSuccess({ lesson: fullLesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public updateLesson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLesson),
      switchMap(({ id, values }) =>
        this.constructorService.updateLesson(id, values).pipe(
          map((lesson: Lesson) => updateLessonSuccess({ lesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public removeLesson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLesson),
      switchMap(({ id }) =>
        this.constructorService.removeLesson(id).pipe(
          map((lesson: Lesson) => removeLessonSuccess({ lesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public publishLesson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishLesson),
      switchMap(({ id }) =>
        this.contentService.publish(id).pipe(
          map((lesson: Lesson) => publishLessonSuccess({ lesson })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  // Slide CRUD

  public loadSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSlide),
      switchMap(({ id }) =>
        this.constructorService.getSlide(id).pipe(
          map((slide: LessonSlide) => loadSlideSuccess({ slide })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public createSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSlide),
      withLatestFrom(this.store.select(getLesson)),
      switchMap(([{ values }, lesson]) =>
        this.constructorService.createSlide(lesson.id, values).pipe(
          tap((slide: LessonSlide) => {
            this.router.navigate(['/constructor/edit', lesson.id, 'constructor', slide.id]);
          }),
          map((slide: LessonSlide) => createSlideSuccess({ slide })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public updateSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSlide),
      switchMap(({ id, values }) =>
        this.constructorService.updateSlide(id, values).pipe(
          map((slide: LessonSlide) => updateSlideSuccess({ id, slide })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public removeSlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSlide),
      switchMap(({ id }) =>
        this.constructorService.removeSlide(id).pipe(
          map((slide: LessonSlide) => removeSlideSuccess({ id })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public copySlide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copySlide),
      switchMap(({ values }) => zip(of(values.sections), this.constructorService.createSlide(values.lesson_id))),
      switchMap(([sections, slide]) =>
        forkJoin(
          sections.map((section) =>
            this.constructorService.createSection(slide.id, section.content.type).pipe(
              concatMap((newSection) => {
                const sec = cloneDeep(section);
                sec.slide_id = slide.id;
                return this.constructorService.updateSection(newSection.id, sec);
              }),
            ),
          ),
        ),
      ),
      switchMap((sections: ILessonSlideSection[]) =>
        this.constructorService.getSlide(sections[0].slide_id).pipe(
          map((slide: LessonSlide) => copySlideSuccess({ slide })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public updateSlides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSlides),
      switchMap(({ slides }) => forkJoin(slides.map((slide) => this.constructorService.updateSlide(slide.id, slide)))),
      tap((slides: LessonSlide[]) => logger.log('---- TAP Slides = ', slides)),
      map((slides: LessonSlide[]) => updateSlidesSuccess({ slides })),
      // switchMap((slides) => this.constructorService.getSlide(sections[0].slide_id).pipe(
      //   map((slide: LessonSlide) => copySlideSuccess({ slide })),
      //   catchError((error: Error) => of(requestFail({ error: error.message }))),
      // ))
    ),
  );

  // Section CRUD

  public loadSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSection),
      switchMap(({ id }) =>
        this.constructorService.getSection(id).pipe(
          map((section: ILessonSlideSection) => loadSectionSuccess({ section })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public createSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSection),
      switchMap(({ slide_id, section_type }) =>
        this.constructorService.createSection(slide_id, section_type).pipe(
          map((section: ILessonSlideSection) => createSectionSuccess({ slide_id, section })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public updateSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSection),
      switchMap(({ id, values }) =>
        this.constructorService.updateSection(id, values).pipe(
          map((section: ILessonSlideSection) =>
            updateSectionSuccess({
              id,
              section: { ...section, show_validate: values.show_validate || section.show_validate },
            }),
          ),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public removeSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSection),
      switchMap(({ id }) =>
        this.constructorService.removeSection(id).pipe(
          map((section: ILessonSlideSection) => removeSectionSuccess({ id })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  public copySection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copySection),
      switchMap(({ values }) =>
        zip(of(values), this.constructorService.createSection(values.slide_id, values.content?.type)),
      ),
      switchMap(([values, section]) => {
        // TODO: проверить copy здесь и copySlide
        const copiedValues = {
          hint: values.hint,
          explanation: values.explanation,
          content: values.content,
        };
        return this.constructorService.updateSection(section.id, copiedValues).pipe(
          map((updatedSection: ILessonSlideSection) => copySectionSuccess({ section: updatedSection })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        );
      }),
    ),
  );

  public updateSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSections),
      switchMap(({ sections }) =>
        forkJoin(
          sections.map((section) => {
            const id = section.id;
            const value = { ...section };
            delete value.id;
            return this.constructorService.updateSection(id, value);
          }),
        ),
      ),
      tap((sections: ILessonSlideSection[]) => logger.log('---- TAP sections = ', sections)),
      map((sections: ILessonSlideSection[]) => updateSectionsSuccess({ sections })),
    ),
  );

  public constructor(
    private actions$: Actions,
    private constructorService: ConstructorService,
    private contentService: ContentService,
    private router: Router,
    private store: Store,
  ) {}
}
