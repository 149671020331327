import { Injectable } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';
import {
  EContentPrivacy,
  ILessonSlide,
  ILessonSlideSection,
  ILessonSlideTaskAnswerVariant,
  Lesson,
  LessonSlideAudio,
  LessonSlideContentTypeEnum,
  LessonSlideImage,
  LessonSlideReading,
  LessonSlideTaskFillGaps,
  LessonSlideTaskMatchPairs,
  LessonSlideTaskRadioList,
  LessonSlideTheory,
  LessonSlideVideo,
  otherSubjectId,
} from 'lingo2-models';
import { ReplaySubject } from 'rxjs';

// TODO: move to lingo2-models
import { ILessonComplete, ILessonSlideComplete, ILessonSlideSectionComplete } from '../content-form-page/_models';

function isEmptyHTML(html: string): boolean {
  const h1 = html.replace(/&nbsp;/g, '');
  const h2 = h1.replace(/\s+/g, '');
  const h3 = h2.replace(/<br\/>/g, '');
  const h4 = h3.replace(/<p>\s*<\/p>/g, '');
  return h4.length === 0;
}

function isEmptyText(txt: string): boolean {
  return !txt || isEmptyHTML(txt || '') || txt.length === 0;
}

export enum WarningsEnum {
  no_subject_id,
  no_title,
  no_level_id,
  no_language_id,
  no_description,
  no_cover_id,
  no_privacy_custom_user_ids,
  no_slides,
  not_completed_slides,
  has_duplicate_variant,
}

const WARNING_MESSAGES = {
  [WarningsEnum.no_subject_id]: 'constructor2.warnings.empty-subject',
  [WarningsEnum.no_title]: 'constructor2.warnings.empty-title',
  [WarningsEnum.no_level_id]: 'constructor2.warnings.empty-level',
  [WarningsEnum.no_language_id]: 'constructor2.warnings.empty-language',
  [WarningsEnum.no_description]: 'constructor2.warnings.no-description',
  [WarningsEnum.no_cover_id]: 'constructor2.warnings.empty-cover-image',
  [WarningsEnum.no_privacy_custom_user_ids]: 'constructor2.warnings.no-students',
  [WarningsEnum.no_slides]: 'constructor2.warnings.few-slides',
  [WarningsEnum.not_completed_slides]: 'constructor2.warnings.incomplete-slides',
  [WarningsEnum.has_duplicate_variant]: 'constructor2.warnings.has-duplicate-variant',
};

@Injectable()
export class ContentValidationService extends DestroyableComponent {
  private isErrorsVisible = this.register(new ReplaySubject<boolean>(1));
  public isErrorsVisible$ = this.isErrorsVisible.asObservable();

  public showErrors(): void {
    this.isErrorsVisible.next(true);
  }

  public hideErrors(): void {
    this.isErrorsVisible.next(false);
  }

  public static validateLesson(lesson: Lesson): ILessonComplete {
    let has_duplicate_variant: any = [];
    let has_invalid_sections = {};
    const incompletedSlides = lesson.slides.filter((slide) => {
      const slideComplete = ContentValidationService.validateLessonSlide(slide);
      has_invalid_sections = { ...has_invalid_sections, ...slideComplete.has_invalid_sections };
      delete slideComplete.has_invalid_sections;
      const res = ContentValidationService.hasDuplicateVariant(slide);
      if (res.length) {
        has_duplicate_variant.push(...res);
      }
      return Object.keys(slideComplete).some((key) => !!slideComplete[key]);
    });
    has_duplicate_variant = has_duplicate_variant.length ? has_duplicate_variant : false;

    const not_completed_slides = incompletedSlides.length ? incompletedSlides.map((x) => x.id) : false;

    return {
      no_subject_id:
        lesson.subject_id === otherSubjectId
          ? !lesson.subject_other_name
          : !(lesson.subject_id ? lesson.subject_id : lesson.subject ? lesson.subject.id : false),
      no_title: !lesson.title,
      no_sub_title: !lesson.sub_title,
      no_level_id: lesson.level_other_name
        ? false
        : !(lesson.level_id ? lesson.level_id : lesson.level ? lesson.level.id : false),
      no_language_id: !(lesson.language_id ? lesson.language_id : lesson.language ? lesson.language.id : false),
      no_description: (lesson.description || '').length < 30,
      no_cover_id: !(lesson.cover_id ? lesson.cover_id : lesson.cover ? lesson.cover.id : false),
      no_privacy_custom_user_ids:
        lesson.privacy === EContentPrivacy.custom &&
        (!lesson.privacy_custom_user_ids || !lesson.privacy_custom_user_ids.length),
      no_slides: !lesson.slides || !lesson.slides.length,
      not_completed_slides,
      has_duplicate_variant,
      has_invalid_sections,
    };
  }

  public static validateLessonSlide(slide: ILessonSlide): ILessonSlideComplete {
    const completion: Partial<ILessonSlideComplete> = {};
    let incompletedSections = [];
    const validity = {};

    if (slide.sections) {
      incompletedSections = slide.sections.filter((section) => {
        const sectionComplete = ContentValidationService.validateLessonSlideSection(section);
        validity[section.id] = sectionComplete;
        return Object.keys(sectionComplete).some((key) => !!sectionComplete[key]);
      });
    }

    completion.not_completed_sections = incompletedSections.length ? incompletedSections.map((x) => x.id) : false;

    return {
      has_invalid_sections: validity,
      no_sections: !slide.sections || !slide.sections.length,
      not_completed_sections: incompletedSections.length ? incompletedSections.map((x) => x.id) : false,
    };
  }

  public static validateLessonSlideSection(section: ILessonSlideSection): Partial<ILessonSlideSectionComplete> {
    const completion: Partial<ILessonSlideSectionComplete> = {};

    switch (section.content.type) {
      case LessonSlideContentTypeEnum.TaskRadioList:
      case LessonSlideContentTypeEnum.TaskCheckList:
      case LessonSlideContentTypeEnum.TaskTable:
        {
          const content = section.content as LessonSlideTaskRadioList;
          const variants = (content.variants || []).filter((v) => !ContentValidationService.isEmptyVariant(v));
          const correctVariants = variants.filter((v) => v.is_correct);

          if (variants.length) {
            const set = new Set();
            content.variants.forEach((item) =>
              item.text ? set.add(item.text.trim()) : item?.image_id ? set.add(item.image_id) : null,
            );
            completion.has_duplicate = variants.length > set.size;
          }
          completion.empty = isEmptyText(content.question);
          completion.few_variants = variants.length < 2;
          completion.no_correct = correctVariants.length < 1;
        }
        break;

      case LessonSlideContentTypeEnum.TaskMatchPairs:
        {
          const content = section.content as LessonSlideTaskMatchPairs;
          const leftVariants = (content.left || []).filter((v) => !ContentValidationService.isEmptyVariant(v as any));
          const rightVariants = (content.right || []).filter((v) => !ContentValidationService.isEmptyVariant(v as any));
          const totalLength = (content.left || []).length;

          if (content.left) {
            const set = new Set();
            leftVariants.forEach((item) =>
              item?.text ? set.add(item.text.trim()) : item?.image_id ? set.add(item.image_id) : null,
            );
            completion.has_duplicate = leftVariants.length > set.size;
          }
          if (rightVariants) {
            const set = new Set();
            rightVariants.forEach((item) =>
              item?.text ? set.add(item.text.trim()) : item?.image_id ? set.add(item.image_id) : null,
            );
            completion.has_duplicate = rightVariants.length > set.size;
          }

          completion.empty = isEmptyText(content.question);
          completion.few_variants = leftVariants.length < 2;
          completion.incomplete_pairs =
            totalLength !== leftVariants.length || leftVariants.length !== rightVariants.length;
        }
        break;

      case LessonSlideContentTypeEnum.TaskFillGaps:
        {
          const content = section.content as LessonSlideTaskFillGaps;
          const gapsFilterList = ['<gap-selectbox>', '<gap-inputbox>', '<gap-dropbox>'];
          const gapsList = gapsFilterList.filter((_fl) => content.text && content.text.indexOf(_fl) >= 0);
          completion.empty_gaps = gapsList.length <= 0;
          completion.empty = isEmptyText(content.question) || isEmptyText(content.text);
        }
        break;

      case LessonSlideContentTypeEnum.Theory:
        {
          const content = section.content as LessonSlideTheory;
          completion.empty = isEmptyHTML(content.text || '');
        }
        break;

      case LessonSlideContentTypeEnum.Audio:
        {
          const content = section.content as LessonSlideAudio;
          completion.incomplete_media = !(content.media_id || false);
        }
        break;

      case LessonSlideContentTypeEnum.Video:
        {
          const content = section.content as LessonSlideVideo;
          completion.incomplete_media = !(content.media_url || false) && !(content.media_id || false);
        }
        break;

      case LessonSlideContentTypeEnum.Reading:
        {
          const content = section.content as LessonSlideReading;
          completion.empty = isEmptyHTML(content.text || '');
        }
        break;

      case LessonSlideContentTypeEnum.Image:
        {
          const content = section.content as LessonSlideImage;
          completion.incomplete_media = !(content.media_id || false);
        }
        break;
    }

    return completion;
  }

  public static isEmptyVariant(variant: ILessonSlideTaskAnswerVariant): boolean {
    return (
      isEmptyHTML(variant.text || '') &&
      !(variant.image_id || false) &&
      !(variant.audio_id || false) &&
      !(variant.video_id || false) &&
      (variant.video_url || '').length === 0
    );
  }

  public static getValidationMessages(validity: ILessonComplete, warnings?: WarningsEnum[]): string[] {
    return Object.keys(validity)
      .map((key) => {
        if (
          validity[key] &&
          WARNING_MESSAGES[WarningsEnum[key]] &&
          (warnings ? warnings.indexOf(WarningsEnum[key]) > -1 : true)
        ) {
          return WARNING_MESSAGES[WarningsEnum[key]];
        }
        return false;
      })
      .filter((x) => !!x);
  }

  static hasDuplicateVariant(slide: ILessonSlide): string[] {
    const ids = [];
    if (slide.sections) {
      slide.sections.forEach((section) => {
        const set = new Set();
        switch (section.content.type) {
          case LessonSlideContentTypeEnum.TaskRadioList:
          case LessonSlideContentTypeEnum.TaskCheckList:
          case LessonSlideContentTypeEnum.TaskTable:
            const content = section.content as LessonSlideTaskRadioList;
            if (content.variants) {
              content.variants.forEach((item) =>
                item.text ? set.add(item.text.trim()) : item?.image_id ? set.add(item.image_id) : null,
              );
              if (content.variants.length > set.size) {
                ids.push(section.id);
              }
            }
            break;
        }
      });
    }
    return ids;
  }
}
